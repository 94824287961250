* {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.hero-animated {
  width: 100%;
  height: 80%;
  min-height: 70vh;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}
.hero-animated h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
}

.hero-animated p {
  margin: 0 0 30px 0;
  font-size: 30px;
  font-weight: 400;
}
.hero-animated .animated {
  margin-bottom: 60px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
.page-height{
  min-height: 72vh !important;
}
@media (min-width: 992px) {
  .hero-animated .animated {
    max-width: 45%;
  }
}
@media (max-width: 991px) {
  .hero-animated .animated {
    max-width: 60%;
  }
}
@media (max-width: 575px) {
  .hero-animated .animated {
    max-width: 80%;
  }
}
.hero-animated .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
}
.hero-animated .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}
.hero-animated .btn-watch-video i {
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}
.page-height{
  min-height: 72vh !important;
}
@media (max-width: 640px) {
  .hero-animated h2 {
    font-size: 32px;
  }
  .hero-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .hero-animated .btn-get-started, .hero-animated .btn-watch-video {
    font-size: 14px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.Polaris-TopBar-Menu__Activator{
  padding: 0rem 0rem !important;
  margin-right: 0 !important;
}


