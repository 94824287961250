.navbar_ul_main {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
    Roboto, Helvetica Neue, sans-serif;
  padding: 16px 0;
  width: 240px;
  background-color: #f4f6f8;
  margin-bottom: 0;
  border-right: 1px solid #dfe3e8;
  overflow-y: auto;
  li {
    list-style: none;
  }
  .navbar_li {
    .navbar_div {
      .navbar_inner {
        .Polaris-Navigation__Badge {
          float: right;
          .Polaris-Badge--statusNew {
            background: linear-gradient(to bottom, var(--p-action-primary), var(--p-action-primary));
            color: white;
            font-weight: 600;
          }
        }
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 3.2rem;
        margin: 5px 10px;
        padding: 0 8px;
        align-items: center;
        cursor: pointer;
        .Polaris-Icon {
          margin: 6px 16px 6px 0;
          svg {
            fill: #919eab;
            color: white;
          }
        }
        .navbar_text {
          margin: 6px 0;
          // color: var(--p-action-primary);
          font-size: 14px;
          line-height: 20px;
          min-width: 136px;
        }
      }
    }

    .sub_div {
      display: none;
      margin-top: 2px;
      margin-left: 36px;
      margin-bottom: 8px;
      .sub_ul {
        padding: 0;
        .sub_inner_div {
          cursor: pointer;
          .sub_btn {
            margin: 0 8px;
            padding: 0 8px;
            display: block;
            .sub_title {
              display: inline-block;
              margin: 4px 0;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #454f5b;
            }
            .Polaris-Navigation__Badge {
              float: right;
              margin-right: 2px;
              .Polaris-Badge--statusNew {
                background: linear-gradient(to bottom, var(--p-action-primary), var(--p-action-primary));
                color: white;
                font-weight: 600;
              }
            }
            @media (max-width: 768px) {
              display: inline-block;
              max-width: 100%;
              width: 90%;
            }
          }
        }
        .sub_inner_div:hover {
          .sub_btn {
            background-color: #edf0f3;
            .sub_title {
              color: var(--p-action-primary);
            }
          }
        }
      }
    }
  }
  & .active_child {
    .navbar_div {
      .navbar_inner {
        .Polaris-Icon {
          svg {
            fill: var(--p-action-primary);
          }
        }
        .navbar_text {
          color: var(--p-action-primary);
        }
      }
    }
    .sub_div {
      display: block;
      .sub_ul {
        .active_sub_child {
          .sub_btn {
            background-color: #e1e5f2;
            .sub_title {
              color: var(--p-action-primary);
              font-weight: 600;
            }
          }
        }
        .active_sub_child:hover {
          .sub_btn {
            background-color: #e1e5f2;
            .sub_title {
              color: var(--p-action-primary);
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  & .active_main {
    .navbar_div {
      .navbar_inner {
        background-color: #e1e5f2;
        .Polaris-Icon {
          svg {
            fill: var(--p-action-primary);
          }
        }
        .navbar_text {
          color: var(--p-action-primary);
          font-weight: 600;
        }
      }
    }
  }
  .active_main:hover {
    .navbar_div {
      .navbar_inner {
        background-color: #e1e5f2;
        .Polaris-Icon {
          svg {
            fill: var(--p-action-primary);
          }
        }
        .navbar_text {
          color: var(--p-action-primary);
          font-weight: 600;
        }
      }
    }
  }
  .navbar_div:hover {
    .navbar_inner {
      background-color: #edf0f3;
      .Polaris-Icon {
        svg {
          fill: var(--p-action-primary);
        }
      }
      .navbar_text {
        color: var(--p-action-primary);
      }
    }
  }
}
.flex_view_xs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &.left {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  &.right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  &.center {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  &.space-between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &.space-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: justify;
    justify-content: space-around;
  }
  &.middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &.end {
    -webkit-align-items: end;
    -ms-flex-align: end;
    align-items: end;
  }
  &.end {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  &.reverse {
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  &.space {
    div[class^="col"] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  > div[class^="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

Menu Set
li.navbar_li:nth-last-child(4) {
  width: 100%;
  max-width: 100%;
  bottom: 160px;
}
li.navbar_li:nth-last-child(3) {
  width: 100%;
  max-width: 100%;
  bottom: 120px;
}
li.navbar_li:nth-last-child(2) {
  width: 100%;
  max-width: 100%;
  bottom: 80px;
}
li.navbar_li:last-child {
  width: 100%;
  max-width: 100%;
  bottom: 40px;
}
